<!-- 受理派遣内容 -->
<template>
  <div class="">
    <ax-form ref="form1" :formBuilder="formBuilder1">
      <!-- 派遣人员 -->
      <div
        slot="dispatchedPersonnel"
        slot-scope="{ record }"
        style="display: flex"
      >
        <a-input
          disabled
          v-decorator="[
            record.model,
            {
              rules: record.rules,
            },
          ]"
          placeholder="请输入"
        ></a-input>
        <ax-button @click="choosePeople" style="margin-left: 2%"
          >选择</ax-button
        >
      </div>
    </ax-form>
    <div class="bottom">
      <time-line :steps="steps"></time-line>
    </div>
    <div class="btn-group">
      <!-- v-if="status1 === 1" -->
      <div style="display: flex; justify-content: flex-end">
        <ax-button @click="returnEvent" style="margin: 0 1%" type="#F95A5A">取消</ax-button>
        <ax-button @click="submit" type="#67C23A">派遣</ax-button>
      </div>
    </div>
    <choose-people
      ref="choosePeople"
      @receive="receiveDispatchedPersonnel"
    ></choose-people>
    <!-- 人员接收-退回弹窗 -->
    <el-dialog
      title="受理派遣"
      :visible.sync="visible"
      width="40%"
      @click="closeDialog"
      append-to-body
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-input
        type="textarea"
        :rows="4"
        placeholder="(必填)请填写退回理由"
        v-model="textarea"
      >
      </el-input>
      <div style="display: flex; justify-content: flex-end; margin-top: 5%">
        <a-button style="margin-left: 20px; margin: 0 1%" @click="closeDialog">
          取消
        </a-button>
        <a-button type="primary" @click="onSubmit" :loading="saveType">
          确认
        </a-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ChoosePeople from "./choosePeople.vue";
import TimeLine from "./timeLine.vue";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
const formList1 = [
  {
    label: "派遣人员",
    type: "",
    model: "dispatchedPersonnel",
    options: { placeholder: "登记人", maxLength: 18 },
    col: { span: 24 },
    rules: [{ required: true, message: "请选择派遣人员" }],
  },
  {
    label: "派遣说明",
    type: "textarea",
    model: "dispatchedInstructions",
    options: { placeholder: "请输入", minRows: 4, maxLength: 200 },
    col: { span: 24 },
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { ChoosePeople, TimeLine },
  props: ["status1", "steps"],
  data() {
    //这里存放数据
    return {
      formBuilder1: this.$common.initGridFormData(
        formList1,
        { layout: "horizontal", labelWidth: 120 },
        { col: { span: 8 } }
      ),
      dispatchedPersonnelName: "",
      dispatchedPersonnelInfo: {},
      textarea: "",
      visible:false,
      saveType: true,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    returnEvent() {
      this.visible = true;
    },
    //关闭弹窗
    closeDialog() {
      this.visible = false;
    },
    //提交
    async onSubmit() {
      console.log(this.textarea);
    },
    // 打开选择派遣人员弹窗
    choosePeople() {
      this.$refs.choosePeople.openModal();
    },
    // 根据选择人员给form的派遣人员赋值
    receiveDispatchedPersonnel(item) {
      if (item[0].id) {
        this.dispatchedPersonnelInfo = item[0];
        this.$refs.form1.setFieldsValue({
          dispatchedPersonnel: item[0].residentName,
        });
      }
    },
    submit() {
      this.$refs.form1.form.validateFields(async (errors, value) => {
        if (errors) return;
        console.log(value);
        // this.$emit("submit", {
        //   ...this.dispatchedPersonnelInfo,
        //   dispatchedInstructions: value.dispatchedInstructions,
        // });
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.ant-form {
  border: 1px solid #e2e8f1;
  padding: 2%;
  padding-bottom: 0;
}
.bottom {
  margin-top: 1%;
}
.btn-group {
  margin-top: 2%;
}
</style>